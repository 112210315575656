import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import logoBrosvets from "../images/logo-brosvets.svg";

const PostLink = ({ post }) => (
  <div>
    
      <div className="sm:flex items-center mb-4 border-b border-grey-lightest pb-4">
        <div className="sm:w-1/4 relative">
          {thumbnailCheck(post.frontmatter.thumbnail)}
        </div>

        <div className="sm:w-3/4 sm:pl-6 sm:pl-12">
          <span className="text-xs text-grey">{post.frontmatter.date}</span>
          <h3 className="mb-0" style={{'marginBottom': .5 + 'rem'}}>{post.frontmatter.title}</h3>
          <p className="text-sm" style={{'marginBottom': 0}}>{post.excerpt}</p>
          <Link
            key={post.frontmatter.title}
            to={post.frontmatter.path}
            className="link text-orange hover:text-orange-dark transition"
            style={{transition: 'color .15s'}}
          >
            Läs mer
          </Link>
        </div>
      </div>
  </div>
)

function thumbnailCheck(thumbnail) {
  if(thumbnail) {
    return (
      <Img fluid={thumbnail.childImageSharp.fluid} />
    )
  } else {
    return (
      <div className="w-full h-full py-6 flex items-center justify-center bg-blue">
        <img className="block w-16" style={{'marginBottom': 0, 'opacity': .15}} src={logoBrosvets} alt=""/>
      </div>
    )
  }
}

export default PostLink