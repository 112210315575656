import { graphql } from "gatsby";
import React from "react";
import LayoutSub from "../components/layout-sub";
import SEO from "../components/seo";
import Sidebar from "../components/sidebar";
import ProjectLink from "../components/project-link";

export default ({data}) => (
  <LayoutSub title="Våra projekt">
    <SEO
      title="Våra projekt"
      description={data.markdownRemark.frontmatter.seo.description}
      keywords={data.markdownRemark.frontmatter.seo.keywords.toString().split(',')}
    />
    
    <article id="main-article" className="container flex flex-wrap items-start lg:flex-no-wrap mx-auto p-4 md:p-8 xl:p-16">
      <div role="main" className="article w-full lg:w-2/3 lg:pr-20 xl:pr-40">
        {data.allMarkdownRemark.edges
          .filter(edge => !!edge.node.frontmatter.date)
          .map(edge => <ProjectLink key={edge.node.id} post={edge.node} />)}
      </div>

      <Sidebar data={data.tjanster} />
    </article>
  </LayoutSub>
)

export const query = graphql`
{
  fileName: file(relativePath: { eq: "sub-page-half.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 390) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  markdownRemark(frontmatter: {name: {eq: "Våra projekt"}}) {
    frontmatter {
      title
      name
      slug
      seo {
        description
        keywords
        title
      }
    }
  }
  allMarkdownRemark(sort: {order: DESC, fields: [frontmatter___date]}, filter: {frontmatter: {type: {eq: "projekt"}}}) {
    edges {
      node {
        id
        excerpt(pruneLength: 164)
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          path
          title
          thumbnail {
            childImageSharp {
              fluid {
                src
                sizes
                srcSet
                aspectRatio
              }
            }
          }
        }
        html
      }
    }
  }
  tjanster: allMarkdownRemark(filter: {frontmatter: {type: {eq: "service"}}}, sort: {fields: frontmatter___order}) {
    edges {
      node {
        id
        frontmatter {
          name
          slug
          service_excerpt
        }
      }
    }
  }
  startsida: markdownRemark(frontmatter: {name: {eq: "Startsida"}}) {
    frontmatter {
      seo {
        description
        keywords
        title
      }
      cta_tjanster {
        blocks {
          heading
          body
          url
        }
      }
    }
  }
}
`